<template>
  <div
    class="my-20 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
  >
    <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
      Meme Museum
    </h2>
    <p class="text-xl text-gray-500">
      Share your favorite MEME. Comment, vote and engage with all the cool
      memes.
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
